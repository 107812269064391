.profile {

  .profile-data {
    margin-left: 10px;
    margin-top: 11px;
  }

  .card {
    max-width: 700px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 3rem 25px 0rem;

    span.profile-photo {
      text-align: center;
    }

    img {
      width: 85%;
      max-width: 350px;
      padding-right: 0rem;
      margin-top: 17px;
    }

    input[type="text"] {
      margin-bottom: 13px;
    }

    input[type="file"] {
      display: none;
    }

    label {
      padding: 10px 34px;
      background: var(--dark-blue);
      color: #fff;
      margin-bottom: 10px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      cursor: pointer;
      transition: .5s;
      border: 1px solid #fff;
      border-radius: 8px;
    }

    label:hover {
      color: #333;
      background: #fff;
    }

    .btns {
      font-family: Arial, Helvetica, sans-serif;

      padding: 1rem 66px;
      color: #fff;
      border-color: #fff;
      background: var(--dark-blue);
      border-radius: 8px;
      font-size: 17px;
    }
    .btns:hover{
    color: #333;
background: #fff;
}
    span.profile-data>* {
      padding: 3px 0;
      color: #fff;
    }
  }
}

@media screen and (max-width: 600px) {
  span.profile-photo {
    margin: auto;
  }
}