.add{
margin-bottom: 3rem;
}

.add-sales {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: flex-start;
    margin-left: 5px;
    // gap: 20px 10px;


    
        label {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            color: white;
        }

        .suppliers-container{
            padding: 5px 0px;
            width: 184.5px;
            color: black;
            font-size: 15px;
            ;

        }


        .css-13cymwt-control{
            border-color: #777 ;
            border-radius: 8px;
        }

        .css-t3ipsp-control{
            border-color: #000 ;
            border-radius: 8px;
            box-shadow: 0 0 0 1px #000;
        }
        
        .custom-border {
            color: black;

        }
    }

.asd {
            font-weight: 300;
            padding: 1rem;
            border: 1px solid #777;
            border-radius: 8px;
            margin-bottom: 5px;
            margin-top: 5px;
            
        }
    


.image-preview {
    width: 100%;
    height: 100%;
    max-height: 280px;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s;
}

.image-preview img {
    width: 100%;
}

.image-show {
    display: block;
}

.editor {
    background-color: rgb(255, 255, 255);
}

.noimage {
    color: #fff;
}

.--btns-primary-sales{
    padding: 9.2px 46px;
    margin-top: 5px;
    border-radius: 8px;
    font-size: 15px ;
    color: #fff;
    border-color: #777;
    background: var(--dark-blue);
    border-radius: 8px;

}
.--btns-primary-sales:hover {
    color: var(--dark-blue);
    background-color:#fff;
    font-size: 15px;
}