.search {
  margin: 5px 0;
  margin-bottom: 20px;
  position: relative;
  flex: 1;

  .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  input[type="text"] {
    
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 5px;
    padding-left: 3rem;
    margin: 15px auto;
    width: 100%;
    border: 1px solid rgb(142, 142, 142);
    border-radius: 8px;
    outline: none;
  }
}
