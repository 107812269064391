.product-summary {
    width: 100%;

    .info-summary {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

.card {
    border: 3px solid black;
    // border-bottom: 3px solid var(--light-blue);
    padding: 5px;
    background-color: var(--dark-blue);
}

.card1 {
    background-color: #b624ff;
}

.card2 {
    background-color: #32963d;
}

.card3 {
    background-color: #c41849;
}

.card4 {
    background-color: #03a5fc;
}