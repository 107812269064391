.product-list {
    color: #333;

    .table {
        padding: 5px;
        width: 100%;
        overflow-x: auto;

        .search {
            width: 100%;
            max-width: 300px;
        }

        table {
            border-collapse: collapse;
            width: 100%;
            font-size: 1.4rem;

            thead {
                border-bottom: 2px solid var(--dark-blue);
            }

            th {
                border: 1px solid #eee;
            }

            th,
            td {
                vertical-align: top;
                text-align: left;
                padding: 8px;

                &.icons {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    >* {
                        margin-right: 7px;
                        cursor: pointer;
                        vertical-align: middle;
                        align-self: center;
                    }
                }
            }

            tr {
                border-bottom: 1px solid #ccc;
            }

            tr:nth-child(even) {
                background-color: #fff;
            }

            tbody {
                tr:hover {
                    cursor: pointer;
                    background-color: rgba(31, 147, 255, 0.3);
                }
            }
        }
    }
}