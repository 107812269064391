.product-detail {
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;

    .card {
        margin: 30px;
        align-items: baseline;
    }

    .detail {
        &>* {
            margin-bottom: 2rem;
            margin-left: 3rem;
            margin-right: 3rem;
        }

        .badge {
            background-color: #fff;
            color: var(--dark-blue);
            padding-left: 2px;
            padding-right: 2px;
            border-radius: 3px;
        }
    }
}

.group {
    border: 1px solid var(--dark-blue);
    padding: 5px;
}

.description {
    color: #fff;
}

.color {
    background-color: #fff;
}