.contact {
  .card {
    padding: 1rem;
    border: 1px solid #ccc;
  }

  .card2 {
    padding: 2rem;
    background-color: var(--dark-blue);
    ;
    color: #fff;

    h3,
    p {
      color: #fff;
    }

    .icons {
      margin: 3rem 0;

      span {
        display: flex;
        align-items: baseline;
        margin-bottom: 1rem;

        a,
        p {
          margin-left: 5px;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: flex-start;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    form {
      width: 500px;
      max-width: 100%;
      margin-right: 1rem;
      margin-bottom: 1rem;

      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }

      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 8px;
        font-family: sans-serif;
        outline: none;
      }

      .botao {
        color: #fff;
        border-color: #fff;
        background: var(--dark-blue);
        border-radius: 8px;
        font-size: 17px;
        width: 100%;
        padding: 8px ;
        margin: 0px;
      }

      .botao:hover {
        color: #333;
        background: #fff;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .contact {
    .section {
      flex-direction: column;
    }
  }
}